.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

}

.main-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-content: flex-start;
  overflow: hidden;
  margin-top: 5rem;
  padding: 30px;
}

.routes-container {
  padding: 0 2rem;

}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.intro-text {
  margin-bottom: 30px;
  text-align: center;
  color: #0578c6;
  font-size: 2.5em;
  margin-top: 30px;
}

.text-intro h5 {
  /* text-align: justify; */
  margin-top: 20px;
  font-size: 2em;
  color: #848484;
}

/* .home { */
/* padding: 0 10px; */

/* } */


@media (min-width: 481px) and (max-width: 991px) {
  .text-video-container {
    grid-template-columns: repeat(1, 1fr);
  }
}


.text-content p {
  width: 100%;
  text-align: justify;
  font-family: Arial, sans-serif;
}




.registry-container {
  text-align: center;
  padding: 20px;
}

.registry-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 30px;
  color: #0578c6;
}


.registry-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* margin-top: 10px; */
  margin-bottom: 20px;

}

@media (max-width: 768px) {
  .registry-icons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .registry-icons {
    grid-template-columns: 1fr;
  }
}

.registry-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 50px;
  color: #0578c6;
}

.icon-label {
  margin-top: 10px;
  font-weight: bold;
}

.registry-description {
  margin-top: 20px;
  font-size: 16px;
  /* color: #555; */
  /* text-align: justify; */
  font-family: Arial, sans-serif;
}


.join-section {

  text-align: center;

}

.join-text {
  margin-bottom: 30px;
  color: #0578c6;
  font-size: 2.5em;

}

.join-text p {
  text-align: justify;
  width: 100%;
}

.about-container {
  text-align: center;
  padding: 20px;
}

.headline {
  font-size: 2em;
  margin: 20px 0;
}

.description {
  font-size: 1em;
  margin: 10px 0;
}

.center-button {
  margin: 20px 0;
}


.join-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.join-page h1 {
  font-size: 32px;
  color: #0578c6;
  text-align: center;
  margin-bottom: 20px;
}

.join-page p {
  font-size: 10px;
  color: #333;
  text-align: justify;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.read-more-container {
  text-align: center;
  margin-bottom: 40px;
}

.membership-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}

.membership-option {
  text-align: center;
  width: 30%;
}

.membership-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.membership-option h2 {
  font-size: 20px;
  color: #0578c6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.membership-option .btn {
  display: block;
  width: 100%;
  padding: 10px;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0578c6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


body {
  font-family: Arial, sans-serif;
}

.container {
  margin: 20px;
}

p {
  color: #333;
  font-size: 14px;
}

ul {
  list-style-type: none;
  padding: 0;
}

h3 {
  font-size: 18px;
  color: #0578c6;
  display: flex;
  align-items: center;
}

h3::before {
  content: '';
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 16px;
  background-size: cover;
}

h3:contains("Benin")::before {
  background-image: url('https://flagcdn.com/w20/bj.png');
  /* Benin flag */
}

h3:contains("Botswana")::before {
  background-image: url('https://flagcdn.com/w20/bw.png');
  /* Botswana flag */
}

h3:contains("Gambia")::before {
  background-image: url('https://flagcdn.com/w20/gm.png');
  /* Gambia flag */
}

h3:contains("Kenya")::before {
  background-image: url('https://flagcdn.com/w20/ke.png');
  /* Kenya flag */
}

h3:contains("Mozambique")::before {
  background-image: url('https://flagcdn.com/w20/mz.png');
  /* Mozambique flag */
}

h3:contains("South Africa")::before {
  background-image: url('https://flagcdn.com/w20/za.png');
  /* South Africa flag */
}

li {
  margin-bottom: 10px;
}

strong {
  color: #0578c6;
}

li ul li {
  font-size: 16px;
  color: #333;
}




body {
  /* font-family: Arial, sans-serif; */
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px !important;
}

.container {
  margin: 20px;
}

p {
  color: #333;
  font-size: 18px;
}

strong {
  font-weight: bold;
}

a {
  color: #0578c6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}



.team-info-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.team-status {
  margin-top: 10px;
  color: #333;
}

.disclaimer {
  font-style: italic;
  color: #777;
  margin-top: 5px;
}

.team-details,
.constituency-info {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table td {
  padding: 8px;
  border: 1px solid #ddd;
}

h1,
h2,
h3 {
  color: #0578c6;
}

.flag-icon {
  width: 20px;
  margin-right: 5px;
}

.asn-info,
.constituency-description,
.team-contact-info {
  margin-top: 20px;
}

.asn-info ul {
  list-style-type: none;
  padding-left: 0;
}

.asn-info ul li {
  background-color: #f9f9f9;
  padding: 5px;
  margin: 3px 0;
}

.constituency-description p {
  margin-top: 10px;
}



/* App.css */

.team-details {
  font-family: Arial, sans-serif;
  /* margin: 20px; */
  padding: 20px;
  /* background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.team-details h2 {
  color: #0578c6;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.team-details p {
  color: #333;
  line-height: 1.5;
}

.fields-section {
  margin-bottom: 20px;
}

.fields-section h4 {
  color: #0578c6;
  font-size: 1.4em;
  margin-bottom: 15px;
}

.team-details-section,
.constituency-section,
.contact-info-section {
  margin-bottom: 20px;
}

.team-details-section h5,
.constituency-section h5,
.contact-info-section h5 {
  color: #0578c6;
  font-size: 1.2em;
  margin-bottom: 10px;
}


table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #e2e2e2;
}

table td strong {
  color: #333;
}

.cryptography-info,
.memberships-info,
.classification-info,
.history-info {
  margin-top: 20px;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cryptography-info h3,
.memberships-info h3,
.classification-info h3,
.history-info h3 {
  color: #0578c6;
  font-size: 1.2em;
  margin-bottom: 10px;
}

pre {
  background-color: #efefef;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  white-space: pre-wrap;
}




.container {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin-bottom: 20px;
}

h2 {
  color: #0578c6;
}

h3 {
  color: #444;
}

.info-text {
  font-size: 14px;
  color: #777;
}

.section {
  margin-top: 20px;
}

.training-card {
  max-width: 800px;
  margin: 20px auto;
  background: linear-gradient(to right, #142f67, #0fbe71);
  /* Gradient background */
  border-radius: 15px;
  color: white;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.header {
  text-align: center;
}

.logo img {
  width: 150px;
}

.header h1 {
  font-size: 28px;
  margin-top: 10px;
}

.teams-details,
.contact-infos,
.memberships-infos,
.classifications-infos,
.history-infos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.teams-detail,
.contact-details,
.memberships-details,
.classifications-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.teams-detail strong,
.contact-details strong,
.memberships-details strong,
.classifications-details strong,
.history-details strong {
  display: block;
  margin-bottom: 5px;
}

.cryptography {
  background-color: #f9f9f9;
  padding: 10px;
  border-left: 3px solid #1a73e8;
  border-radius: 4px;
  font-family: monospace;
}



th {
  background-color: #534caf;
  color: white;
}


.history-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}


/* .capacity-buiulding {
  padding: 20px;
  font-family: Arial, sans-serif;display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.events-image  {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.event-tittle  h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
 */

.tba-template-form {
  margin: 20px;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #f9f9f9;
}

.tba-template-form h2,
.tba-template-form h3 {
  font-weight: bold;
}

.tba-template-form p {
  font-size: 14px;
  line-height: 1.6;
}

.tba-template-form a {
  color: #007bff;
  text-decoration: none;
}

.tba-template-form a:hover {
  text-decoration: underline;
}

.submitter-form {
  margin-top: 20px;
}

.submitter-form .form-group {
  margin-bottom: 15px;
}

.submitter-form label {
  display: block;
  margin-bottom: 5px;
}

.submitter-form input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tba-template-form {
  margin: 20px;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #f9f9f9;
}

.h4 {
  font-weight: bold;
  font-size: 14px;
}

.tba-template-form p {
  font-size: 14px;
  line-height: 1.6;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group label {
  display: block;
  margin-bottom: 2px;


}

/* .container {
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.certification-training {
  
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  position: relative;
}

.breadcrumbs {
  color: white;
  font-size: 18px;
}

.highlight {
  color: #00ff00; 
}

.content h2 {
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
}

.content h1 {
  color: #00ff00; 
  font-size: 48px;
  font-weight: bold;
}


.circles {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.circle-big {
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #00ff00 0%, #0033cc 100%);
  border-radius: 50%;
}

.circle-small {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #00ff00 0%, #0033cc 100%);
  border-radius: 50%;
  margin-top: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  
}

.container {
  display: flex;
  background: linear-gradient(to right, #37a000, #003ba2);
  padding: 20px;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  
}

.image {
  width: 180px;
  height: 180px;
  border-radius: 0;
  margin-right: 20px;
}

.text-container {
  color: white;
  max-width: 400px;
}

.name {
  font-size: 32px;
  margin: 0;
  color: white;
}

.subtitle {
  font-size: 24px;
  margin: 5px 0;
}

.description {
  font-size: 18px;
  margin: 10px 0;
}

.button {
  background-color: #4a00b6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.certification-page {
  font-family: Arial, sans-serif;
}

.banner {
 
  text-align: center;
}

.banner-image {
  width: 100%;
  height: auto; 
  margin-top: 10px;
}



.event-details {
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.event-title {
  font-size: 2rem;
  color: #3c3c7b;
  margin-bottom: 20px;
}

.event-description {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.5;
} */

.page-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.certification-training {
  text-align: center;
  padding: 40px 20px;
  /* color: white; */
  height: 400px;
  width: 100%;
  justify-content: center;
  padding-left: 50px;
  position: relative;
}


.circles {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.circle-big {
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #00ff00 0%, #4338BB 100%);
  border-radius: 50%;
}

.circle-small {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #00ff00 0%, #4338BB 100%);
  border-radius: 50%;
  margin-top: 10px;
}

.breadcrumbs {
  font-size: 2rem;
  color: white;
  margin: 20px 0;
  text-align: left;
}

.highlight {
  font-size: 2rem;
  margin: 20px 0;
  color: #00ff00;
  text-align: left;
}


.content h7 {
  font-size: 2em;
  margin: 20px 0;
  color: white;
}

.content h8 {
  font-size: 4rem;
  margin: 20px 0;
  color: #46EE37;
}

.banner {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.banner-image {
  width: 100%;
  height: auto;
}

.event-details {
  /* padding: 40px 20px; */
  /* background-color: #fff; */
  margin-bottom: 30px;
}

.event-title {
  font-size: 2rem;
  color: #0B008F;
  font-weight: bold;
}

.event-description {
  font-size: 1.1rem;
  line-height: 1.5;
}

.facilitators-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (max-width: 1200px) {
  .facilitators-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .facilitators-section {
    grid-template-columns: 1fr;
  }
}

.facilitator-container {
  display: flex;
  background: linear-gradient(to right, #37a000, #4338BB);
  /* padding: 5px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}


.facilitator-image-container {
  width: 30%;
}

.facilitator-image {
  width: 60%;
  height: 40%;
  /* background-size: cover; */
  object-fit: contain;
  border-radius: 0.375rem;
  border: 1px solid transparent;

  /* Space between image and text */
}

.text-container {
  width: 60%;
}

.name {
  font-size: 1.5rem;
  margin: 0;
  color: white;
  font-weight: bold;
}

.subtitles {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  margin-bottom: 15px;
  color: white;
}

.full-description p {
  margin-top: 10px;
  /* text-align: justify; */
  overflow: visible;
  /* Ensure no overflow issues */
  height: auto;
  /* Ensure height adjusts based on content */
  font-family: Arial, sans-serif;
  color: white;
}

.facilitators-button {
  background-color: #0B008F;
  color: white;
  border: none;
  /* padding: 10px 15px; */

  cursor: pointer;
}


@media (max-width: 768px) {
  .facilitator-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    place-items: center;
    padding: 1rem
  }

  .facilitator-image {
    width: 100%;
  }

  .facilitator-image-container {
    width: 100%;
  }

  .text-container {
    width: 100%;
  }
}

/* .important-links-section {
  

} */

/* Styling for the Important Links section */
.important-links-section {
  text-align: left;
  font-size: 2rem;
  padding: 20px, 0;
  color: #0B008F;
  font-weight: bold;
}

.section-titles {
  font-size: 2rem;
  color: #0B008F;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Styling for the container holding the buttons */
.links-container {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0.5px 0;
  background: linear-gradient(to right, #37a000, #003ba2);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

/* Styling for each individual link button */
.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.3s ease;
  margin-bottom: 20px;
  margin-top: 20px;
}

.link-button:hover {
  background: linear-gradient(to right, #2CA950, #4338BB);
  /* Darker gradient on hover */
}

/* Styling for the icons in the buttons */
.icon-book::before {
  content: '📖';
  margin-right: 10px;

}

.icon-video::before {
  content: '🎥';
  margin-right: 10px;

}

.icon-photo::before {
  content: '🖼️';
  margin-right: 10px;
}


.collaboration-faq-container {
  font-family: Arial, sans-serif;
  padding: 10px;
  width: 100%;
  font-weight: bold;


}

.collaborating-organizations h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #0B008F;
}

.organization-logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.org-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.org-group h4 {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 18px;
  color: #003ba2;
}

.org-group img {
  /* max-width: 100px; */
  width: 100px;
  margin-bottom: 10px;
}

.org-sub-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.csirt {
  /* max-width: 100px; */
  width: 160px !important;
  margin-bottom: 10px;
}

.shadowserver {
  /* max-width: 100px; */
  width: 160px !important;
  margin-bottom: 10px;
}


.faq-sections {
  background-color: #4338BB;
  color: white;
  /* padding: 10px;
  border-radius: 10px; */
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;
  width: 100%;
  /* margin: 0 auto;  */

}

.faq-section h9 {
  text-align: center;
  margin-bottom: 40px;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;

}

.faq-item {
  cursor: pointer;
  margin-bottom: 10px;
  /* border-bottom: 1px solid white; */
  padding-bottom: 10px;
  margin-top: 20px;
}

.faq-question {
  display: flex;
  align-items: center;

}

.faq-question p {
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px,
}

.faq-question span {
  font-size: 24px;
  margin-right: 10px;
}

.faq-answer p {
  margin-top: 10px;
  color: white
}