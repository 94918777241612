.about-container {
  text-align: center;
  padding: 20px;
}

.headline {
  margin-top: 5rem;
  font-size: 16px;
  color: #0578c6;

}

.descriptions {
  font-size: 1em;
  margin: 10px 0;
  font-family: Arial, sans-serif;
}

.center-button {
  margin: 20px 0;
}

.steering-committee-container {
  text-align: center;
  padding: 20px;
}

.linkedin-icon {
  margin-left: 8px;

  justify-content: center;
  font-size: 16px;
  color: #0077b5;
}

/* .linkedin-text {
  font-size: 5px;
  color: #0077B5;
  margin-top: 5px;
} */

.linkedin-icon:hover {
  color: #005582;
}


.header-image {
  background-color: #ccc;
  padding: 40px 0;
}

.header-image img {
  max-width: 50%;
  height: auto;
}

.headline {
  font-size: 2.5em;
  margin: 20px 0;
  color: #0578c6;
}

.member-name h3 {
  font-size: 1.5em;
  /* color: #0578c6; */

}

.committee-members {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 768px) {
  .committee-members {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .committee-members {
    grid-template-columns: 1fr;
  }
}

.member-card {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  text-align: left;
}


.full-description {
  margin-top: 10px;
  text-align: left;
  overflow: visible;
  height: auto;
  font-family: Arial, sans-serif;
}

.member-card img {
  width: 100%;
  height: 70%;
  margin-bottom: 10px;
}

.member-card h3 {
  margin-bottom: 10px;
}

.member-card p {
  font-size: 1em;
  margin-bottom: 20px;
}

.read-more {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
}

.read-more:hover {
  background-color: #0056b3;
}


/* .container {
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */
.resource-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    display: none;
  }

  tr {
    margin-bottom: 10px;
  }

  td {
    display: flex;
    justify-content: space-between;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    white-space: nowrap;
    font-weight: bold;
  }
}

h1 {
  color: #0578c6;
  text-align: center;
}

p {
  text-align: justify;
  /* color: #666; */
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter {
  flex: 1;
  min-width: 120px;
  margin: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.buttons {
  text-align: center;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #007BFF;
  cursor: pointer;
  margin: 0 10px;
}

button.reset-btn {
  background-color: #6c757d;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #007BFF;
  color: #fff;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


.join-page {
  text-align: center;
  padding: 20px;
}

.join-page h1 {
  margin-bottom: 20px;
}

.join-page p {
  /* font-size: 1.2em; */
  margin-bottom: 20px;
}

.membership-options {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.membership-option {
  width: 30%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.membership-option h2 {
  margin-bottom: 10px;
}

/* .membership-option p {
  font-size: 1em;
} */

.membership-option button {
  margin-top: auto;
  margin-top: 20px;
}

.apply-now {
  margin: 40px 0;
}

.apply-now h2 {
  margin-bottom: 20px;
}

.apply-now p {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.apply-now button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.apply-now button:hover {
  background-color: #0056b3;
}

.read-more-container {
  margin-top: 20px;
  text-align: center;
}

.read-more-container button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.read-more-container button:hover {
  background-color: #007bff;
}


.home .join-section {
  text-align: center;
  margin: 40px 0;
}

.home .join-section button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
}

.home .join-section button:hover {
  background-color: #0056b3;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}