    /* .footer-container {
     

}  */

    .footer-main {
      background-color: #f5f5f5;
      padding: 20px;
      margin-bottom: 20px;
    }

    .footer-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;
      padding: 0 25px;
    }

    /* .footer-section {
  flex: 1;
  margin-right: 20px;
} */

    .footer-image {
      max-width: 250px;
      width: 100%;
    }

    .footer-links ul {
      list-style: none;
      padding: 0;
    }

    .footer-links li {
      margin-bottom: 5px;
    }

    .footer-links a {
      color: #000;
      text-decoration: none;
    }


    .footer-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .socials {
      display: flex;
      margin-left: auto;
      gap: 0.5rem;
    }

    .social-icons a {
      margin: 0 10px;
      color: #000;
      text-decoration: none;
    }

    .social-icons i {
      font-size: 2.0em;
    }

    .footer-privacy {
      display: flex;
      flex-direction: column;
      /* gap: 1rem; */
    }

    .footer-privacy p {
      text-align: left !important;
    }

    .footer-partners {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      /* text-align: center; */
      background-color: white;
    }

    .partner-logo {
      display: flex;
      width: 50%;

      /* height: 100px; */
      gap: 15px;
      /* background-color: #f5f5f5;  */
    }

    @media (max-width: 768px) {
      .partner-logo {
        width: 100%;
      }
    }

    @media (max-width: 480px) {
      .partner-logo {
        width: 100%;
      }
    }

    /* .partner-logo img { */
    /* width: 50px;
  height: 10px; */
    /* background-color: #f5f5f5; */

    /* }  */

    .footer-bottom1 p {
      text-align: center;
      margin-top: 10px;

    }

    .footer-bottom2 {
      text-decoration: none;
      font-size: 20px;
      color: #000;
      text-align: right;

    }