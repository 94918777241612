/* .media-page {
    padding: 20px;
    font-family: Arial, sans-serif;display: flex;
    flex-direction: column;
    margin-bottom: 40px;

  } */

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 20px;
}

.featured-post {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.featured-image {
  display: block;
  width: 80%;
  height: auto;
  margin-bottom: 20px;
}

.post-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.post-meta {
  color: #777;
  font-size: 12px;
  margin-bottom: 10px;
}

.post-excerpt {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 15px;
}

.other-posts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}


@media (min-width: 481px) and (max-width: 991px) {
  .other-posts {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .other-posts {
    grid-template-columns: repeat(1, 1fr);
  }
}

.post {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.post-image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.events-section {
  margin-top: 20px;
}

.events {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  /* margin-top: 10px; */
}

@media (max-width: 768px) {
  .events {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .events {
    grid-template-columns: 1fr;
  }
}

.event {
  /* width: 30%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.event-caption {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 10px;
}

.media-page {
  padding: 20px;
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 20px;
}



/* .blog-section, */
.events-section,
.media-section {
  margin-bottom: 30px;
}

.media-grid {
  display: flex;
  align-items: center;
}

.media-card {
  background-color: #f8f9fa;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  /* width: 30%; */
}

.media-card.large {
  width: 40%;
}

/* .video-card {
    width: 100%;
  } */

.media-image {
  background-color: #ddd;
  width: 100%;
  /* height: 200px; */
  margin-bottom: 10px;
}

.media-card p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

.explore-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
}

.explore-button:hover {
  background-color: #0056b3;
}