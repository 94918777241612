.about-container {
  text-align: center;
  padding: 20px;
}

.image-section {
  background-color: #ccc;
  padding: 40px 0;
}

.image-section img {
  max-width: 50%;
  height: auto;
}

.headline {
  font-size: 2em;
  margin: 20px 0;
}

/* .description {
    font-size: 1em;
    margin: 10px 0;
  }
   */
.center-button {
  margin: 20px 0;
}

/* New styles for the Join page */
.join-page {
  padding: 20px;
  font-family: Arial, sans-serif;

}

.join-page h1 {
  font-size: 32px;
  color: #007bff;
  text-align: center;
  margin-bottom: 20px;
}

.join-page p {
  font-size: 16px;
  color: #333;
  text-align: justify;
  margin-bottom: 20px;
}

.additional-content {
  margin-top: 10px;
  color: #333;
  font-size: 16px;
  margin-left: 0px;
}

/* .main-list {
    list-style-type: disc;
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    text-align: justify;
    font-family: Arial, sans-serif;
  } */

.sub-list {
  list-style-type: disc;
  margin-left: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  text-align: justify;
  font-family: Arial, sans-serif;

  margin-top: 10px;

  padding-left: 20px;
}

.additional-contents {
  margin-left: 0;
}

.membership-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}

.membership-option {
  text-align: center;
  width: 30%;
}

.membership-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.membership-option h2 {
  font-size: 20px;
  color: #007bff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.membership-option .btn {
  display: block;
  width: 100%;
  padding: 10px;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Join.css */

.listed-team-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: justify;
  /* max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif; */
  /* color: #333333;
    line-height: 1.6; */
  /* background-color: #f9f9f9; */
  /* border-radius: 8px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* margin-bottom: 3rem; */
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #4a90e2;
}

/* .description {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: justify;
  } */

.description strong {
  font-weight: bold;
}

p {
  text-align: justify;
  font-size: 16px;
  /* color: #666; */
}

.description p {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}

.image-placeholder {
  display: flex;
  /* justify-content: center;
    align-items: center;
    height: 200px;  */
  margin-bottom: 30px;
  height: 300px;
  display: flex;

}

.image-placeholder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.process-section {
  margin-bottom: 40px;
}

.process-section h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  color: #4a90e2;
}

.process-section p {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

.process-section ul {
  list-style-type: disc;
  padding-left: 40px;
}



.listedteam-page h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  color: #4a90e2;
}

.listedteam-page ul {
  list-style-type: disc;
  padding-left: 40px;
}

.listedteam-page p {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

.related-materials h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  color: #4a90e2;
}

.related-materials p {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;

}

.related-materials {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.card-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  /* Ensures all cards are the same height */
}


.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  align-items: stretch;
  margin-bottom: 5rem;
}


@media (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}

.card__subtitle {
  writing-mode: vertical-lr;
  margin-right: 1rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.15rem;
  color: #888;
}

.card__content {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.card__img {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.card__title {
  font-size: 1rem;
  margin: 0.5rem 0;
  font-weight: 900;
}

.card__text {
  margin-bottom: 2rem;
}

.card-main .button {
  margin-top: 1rem;
  width: 100%;

}



/* .accredited-team-page {
  padding: 20px;
} */

.title {
  font-size: 24px;
  font-weight: bold;
}

.highlight {
  color: #007bff;
}

/* .description {
  margin-top: 10px;
  font-size: 16px;
}

.description p {
  font-size: 14px;
  line-height: 1.5;
  text-align: justify; 
} */
.image-placeholder {
  margin-top: 20px;
}

.steps-section {
  margin-top: 30px;
}

.steps-section h2 {
  font-size: 20px;
  text-align: center;
}

.steps-section ol {
  margin-left: 20px;
}

.related-materials {
  margin-top: 40px;
}

.related-materials h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff;
}

.related-description {
  font-size: 16px;
  line-height: 1.5;
}

.accredited-team-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: justify;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.highlight {
  color: #007bff;
}

/* .description {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
} */

.image-placeholder {
  margin-top: 20px;
  text-align: center;
  /* max-width: 100%;
  max-height: 100%; */
  height: 300px;
  display: flex;
}

/* .image-placeholder img {
  max-width: 100%;
  max-height: 100%;
} */

.steps-section {
  margin-top: 30px;
}

.steps-section h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff;
  text-align: left;
}

.steps-section ol {
  margin-left: 20px;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}


.associated-expert-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: justify;
}

.content {
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

/* .note {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
} */

.content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: justify
}

.image-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* background-color: #eee; */
  height: 300px;
}

.placeholder-image {
  max-width: 100%;
  max-height: 100%;
}