/* Updated CSS for better responsiveness and readability */

.contact-image {
  width: 6.5rem;
  max-width: 100%;
  margin-bottom: 1rem;
}

.membership-option {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px auto;
}

.membership-option h2 {
  margin-bottom: 10px;
}

.membership-option p {
  font-size: 1em;
}

.membership-option button {
  margin-top: 20px;
}

.apply-now {
  margin: 40px 0;
  text-align: center;
}

.apply-now h2 {
  margin-bottom: 20px;
}

.apply-now p {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.apply-now button,
.read-more-container button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.apply-now button:hover,
.read-more-container button:hover {
  background-color: #0056b3;
}

.home .join-section {
  text-align: center;
  margin: 40px 0;
}

.home .join-section button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.home .join-section button:hover {
  background-color: #0056b3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.confidentiality-email {
  margin: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.confidentiality-email h2,
.service-governance h2 {
  margin-top: 10px;
  color: #0578c6;
  margin-bottom: 15px;
  font-size: 2em;
}

.confidentiality-email p,
.service-governance p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.pgp-box {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  padding: 15px;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  margin-bottom: 20px;
  overflow-wrap: break-word;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.contact-item {
  flex: 1 1 45%;
  margin-bottom: 20px;
}

.icon {
  width: 90px;
  color: #0578c6;
}

.contact-item h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-item p {
  font-size: 14px;
  color: #0578c6;
}

.message-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.message-heading {
  text-align: center;
}

.subtitle {
  font-size: 32px;
  color: #0578c6;
}

.faq-section {
  margin-top: 20px;
  padding: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h4 {
  font-size: 16px;
  color: #0578c6;
}

.service-governance ul {
  list-style-type: disc;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.service-governance ul li {
  margin-bottom: 10px;
}

.service-governance a {
  color: #0578c6;
  text-decoration: none;
}

.service-governance a:hover {
  text-decoration: underline;
}

.image-overlay {
  position: relative;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-overlay:hover .overlay {
  opacity: 1;
}

@media (max-width: 768px) {
  .contact-item {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .contact-image {
    width: 100%;
  }

  .pgp-box pre {
    font-size: 12px;
  }

  .service-manager h3 {
    font-size: 18px;
  }

  .service-manager p {
    font-size: 14px;
  }
}